import { render, staticRenderFns } from "./Largescreen copy.vue?vue&type=template&id=9082021e&scoped=true&"
import script from "./Largescreen copy.vue?vue&type=script&lang=js&"
export * from "./Largescreen copy.vue?vue&type=script&lang=js&"
import style0 from "./Largescreen copy.vue?vue&type=style&index=0&id=9082021e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9082021e",
  null
  
)

export default component.exports